import React from 'react';
import MainWrapper, {LogoImageContainer, MainContentSection} from "@deadline/common/ui/five.style";
import {Container} from "../components/Container";
import styled from "styled-components";
import {Link} from "gatsby";
import LogoImage from '@deadline/common/static/images/logoWhite.svg';
import {SEO} from "../components";
import Helmet from "react-helmet";

const TextWrapper = styled.div`
  margin-top: 100px;
  background: #fff;
  padding: 50px;
  border-radius: 5px;
  width: 100%;
  *{
    max-width: initial !important;
    text-align: justify !important;
     font-family: 'Roboto' !important;
    
  }
`;

function ErrorPage() {
  return (
      <MainWrapper>
          <Helmet>
              <title>
                  Simplicity Commerce | Terms of Service
              </title>
          </Helmet>
        <Container className="mainContainer">
          <MainContentSection>
              <LogoImageContainer>
                  <Link to={'/'}>
                      <img src={LogoImage} alt="logo" />
                  </Link>
              </LogoImageContainer>

            <TextWrapper>
                <h1>Terms of Service</h1>
                <div className="col-sm-8 mx-auto">
                    <h3>Published Date: November 28th, 2019</h3>
                    <h2 id="SimplicityCommercecom-terms-of-service">SimplicityCommerce.com Terms of Service</h2>
                    <p>This terms of use agreement (the “Agreement”) governs your access to, and use of, the websites,
                        services and applications (collectively, the “Services”) which are owned, operated or provided
                        by or on behalf of Simplicity Commerce, Inc. (“Simplicity”). &nbsp;</p>
                    <p>As used in this Agreement, “you” and “your” refers to you, the person accessing the Services;
                        “we” means (and “us”, “our”, “ours” and “ourselves” refer to) Simplicity; and “party” or “parties”
                        refers to both you and us. &nbsp;&nbsp;The effective date of this Agreement is when you accept
                        it, in accordance with the terms and conditions that are set out below.</p>
                    <p>IMPORTANT! YOUR ACCESS TO AND USE OF THE SERVICES IS SUBJECT TO LEGALLY BINDING TERMS AND
                        CONDITIONS. &nbsp;&nbsp;PLEASE CAREFULLY READ ALL OF THE FOLLOWING TERMS AND CONDITIONS BEFORE
                        YOU PROCEED. ACCESSING OR USING THE SERVICES IS THE EQUIVALENT OF YOUR SIGNATURE AND INDICATES
                        YOUR ACCEPTANCE OF THESE TERMS AND CONDITIONS AND THAT YOU INTEND TO BE LEGALLY BOUND BY THEM.
                        IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS, PLEASE DO NOT USE THE SERVICES.</p>
                    <p>You can view, print, download or save this Agreement at any time, under the “Terms of Service”
                        link that appears on our website.</p>
                    <p>We reserve the right to change these terms and conditions at any time without notice, by updating
                        this Agreement, and such changes will be effective as of the date these updates (or an updated
                        version of this Agreement) is posted to our website. &nbsp;&nbsp;We may provide notice to you of
                        material revisions by means of a general notice on our website. Your continued use of the
                        Services after such revisions are posted will signify your agreement to these revised terms.
                        Therefore, you should visit this page periodically to review this Agreement. &nbsp;</p>
                    <h2 id="our-services">Our Services</h2>
                    <p>We develop and make available to you apps which are designed to enhance your e-commerce
                        platform. &nbsp;&nbsp;Our eCommerce apps are built to provide additional value-add features to
                        your existing storefront. In some cases, our apps may permit you to receive, sell, and otherwise
                        use material (“Uploaded Content”) which is uploaded by third parties (“Content Uploaders”). For
                        more information about Uploaded Content and Content Uploaders, please see the “ Uploaded
                        Content” heading below. &nbsp;We can also custom develop apps for your store, provide webmaster
                        services, and many other e-commerce services. The list of apps we offer is always changing - you
                        can access a list of our current apps <a href="/apps">here</a>.</p>
                    <p>The specifics of each aspect of the Services (including technical details, support and pricing
                        information) are posted on our website, as we may amend them from time to time, as well as in
                        any applicable statement of work we may issue to you, in respect of the
                        Services. &nbsp;&nbsp;Where you engage us to custom develop Services, the details of our
                        engagement will be governed by any additional documents and agreements as part of that
                        engagement.</p>
                    <p>We take reasonable efforts to explain each Service’s features to you on our website, but we can’t
                        guarantee a Service will look or perform exactly like it appears on a demo page. &nbsp;If you
                        have any questions about any terms or details of any of our Services, please reach out to us at
                        the contact information below.</p>
                    <p>Simplicity shall use commercially reasonable efforts to provide technical support for
                        Services. &nbsp;We are not responsible for providing technical support for any apps, products,
                        or services provided to you by third parties.</p>
                    <h2 id="payments--terms">Payments &amp; Terms</h2>
                    <p>All Services are provided on a per store basis, meaning you will have to purchase Services on an
                        individual store basis. &nbsp;For example, if you have two stores, and you wish to use one of
                        our apps for both stores, you must purchase two subscriptions to that app. &nbsp;</p>
                    <p>Unless otherwise indicated, the sale is finalized when you conclude the purchase of the Service,
                        and your method of payment will be charged following your predefined billing cycle on that
                        eCommerce platform. When you make a payment, you confirm that you are entitled to use the method
                        which you have used to make the payment.</p>
                    <p>All payments to Simplicity do not include applicable sales, goods and services, harmonized, or any
                        other &nbsp;taxes or fees, which may be charged by governmental authorities. Such taxes and/or
                        fees will be added to the total amount you are required to pay and billed to your method of
                        payment. Payment of these taxes and/or fees will be your sole responsibility.</p>
                    <p>You agree to indemnify and hold harmless Simplicity and its officers, directors, subsidiaries,
                        affiliates, employees, representatives, agents, licensors and their respective successors and
                        assigns (collectively, its “Others”), against any chargeback costs (plus any taxes or related
                        fees) which we are required to pay, as a result of a payment dispute with you or in relation to
                        a payment you have made to us for the Services.</p>
                    <p>All payments are made payable in USD (United States Dollars)</p>
                    <h2 id="trial-period">Trial Period</h2>
                    <p>We may offer a trial period for certain Services before being required to purchase or
                        subscribe. &nbsp;The duration and specific terms of the trial period will be published to our
                        websites and during the Services install process. &nbsp;You will not be charged for the Services
                        until the trial period has expired.</p>
                    <p>You agree that even though you may have access to the Services on a trial basis, free of charge,
                        you will be bound by the terms and conditions of this Agreement. Your continued use of the
                        Services following the conclusion of the trial period constitutes your consent to be charged for
                        those Services and to the provisions in this Agreement.</p>
                    <h2 id="right-to-use-the-services-and-intellectual-property">Right to use the Services and
                        Intellectual Property</h2>
                    <p>Subject to your compliance with the terms and conditions of this Agreement, we hereby grant to
                        you a limited, non-exclusive, non-assignable, non-sublicensable, revocable right to install and
                        use the Services for the purpose of operating your online store. &nbsp;This right terminates
                        upon termination of this Agreement or any other agreements previously provided to you by us, as
                        may be applicable. Any and all rights not expressly granted to you are reserved by us, and this
                        Agreement does not confer to you a proprietary interest in any Services.</p>
                    <p>All materials displayed or otherwise accessible on or through the Services, including source code
                        (“Our Content”), and the selection and arrangement of Our Content, are protected by copyright,
                        pursuant to Canadian copyright laws, international conventions and other copyright
                        laws. &nbsp;&nbsp;We either own the intellectual property rights, including copyright, or have
                        acquired the necessary rights or licences, in Our Content. Portions of Our Content may have been
                        licensed to us or published on our website, or as part of our Services, by third parties. Any
                        reproduction, modification, publication, transmission, transfer, sale, distribution, display or
                        exploitation of the Services or Our Content, whether in whole or in part, without our express
                        written permission, is strictly prohibited. &nbsp;</p>
                    <p>Certain words, phrases, names, designs or logos made available on or through the Services may
                        constitute trademarks, services marks, or trade names that are owned by us or
                        others. &nbsp;&nbsp;The display of such marks on or through the Services does not imply that you
                        have been granted a licence by us or others with respect to them.</p>
                    <p>If you believe in good faith that any material that is made available on or through the Services,
                        infringes your copyright, please notify us using the <a href="/contact">Contact Us</a> link on
                        our website.</p>
                    <p>Subject to the terms and conditions of this Agreement, you are granted a limited personal,
                        non-exclusive, non-transferable, revocable license to access, view and use our website,
                        including a limited license to download, print and store single copies of Our Content (other
                        than source code) from our website, for your personal use, provided that you maintain all
                        copyright and other notices contained in such items and you do not (and do not allow any third
                        party to) copy, modify, create a derivative work of, reverse engineer, reverse assemble or
                        otherwise attempt to discover, any source code, in our website. &nbsp;&nbsp;Our Content must not
                        be reproduced, republished, or disseminated in any manner or form without our prior written
                        consent or the prior written consent of the third parties from which such information was
                        collected, as the case may be.</p>
                    <h2 id="continued-use-updates-and-upgrades">Continued Use, Updates and Upgrades</h2>
                    <p>We reserve the right to modify, update, remove or disable access to any Services, without notice
                        to you, and we will not be liable to you if we exercise those rights. &nbsp;&nbsp;When you
                        purchase a particular Service, you are purchasing the right to use that Service as of the time
                        you purchase it. You are not paying for the right to any updates, upgrades or future versions of
                        the Services, though we may make such updates, upgrades or future versions available to you, in
                        our sole discretion. &nbsp;&nbsp;We do not warrant, represent or undertake to provide any
                        updates and in no event shall we be liable to you for our failure to do so.</p>
                    <h2 id="third-party-services-and-content">Third Party Services and Content</h2>
                    <p>We are not a party to any relationship between you and any third party, including, but not
                        limited to, you and your eCommerce platform or you and your customers (your “Customers”), and as
                        such, we have no responsibility to you as regards to your relationships with these third
                        parties. &nbsp;&nbsp;You acknowledge and agree that you have no recourse against us for any acts
                        or omissions of third parties, and your interaction with third parties is entirely at your own
                        risk.</p>
                    <p>Your use of the Services may rely on services and products which are offered by third parties
                        (“Third Party Services”). &nbsp;We have no responsibility to you for anything third parties do
                        (or fail to do) and we provide no warranties or guarantees about third parties or Third Party
                        Services. &nbsp;&nbsp;Your use of Third Party Services may be subject to specific terms and
                        conditions which are set by those third parties.</p>
                    <p>We may make third parties’ content and materials (“Third Party Content”) available to you through
                        our websites, such as reviews. &nbsp;&nbsp;Our making available of such Third Party Content does
                        not constitute an endorsement or recommendation, and we are not responsible for any reliance you
                        may place on Third Party Content. &nbsp;&nbsp;We make no warranties or representations as to any
                        Third Party Content and we shall have no liability for it. Any opinions or statements made by
                        third parties are those of such third parties, and do not necessarily state or reflect our
                        views.</p>
                    <p>You agree that we will have no liability to you with respect to the acts, omissions, errors,
                        representations, warranties, breaches or negligence for any damages or expenses resulting in any
                        manner from your interactions with any: a) third parties; b) Third Party Services; or c) Third
                        Party Content, and we are not obliged to become involved in any disputes you may have with any
                        third parties. &nbsp;If you have a dispute with any third parties, you release Simplicity and its
                        Others from any direct, indirect, incidental, special, consequential, exemplary or other damages
                        whatsoever, including, without limitation, any direct, indirect, incidental, special,
                        consequential, exemplary or other damages, arising out of or in any way related to such disputes
                        and/or our Services.</p>
                    <h2 id="uploaded-content">Uploaded Content</h2>
                    <p>Some of our Services may permit you to sell or otherwise enable Uploaded Content, which is
                        material that has been uploaded by Content Uploaders. &nbsp;&nbsp;When you sell or otherwise
                        enable Uploaded Content from Content Uploaders, the following rules apply (in addition to all
                        other terms of this Agreement). &nbsp;You grant us a non-exclusive, worldwide, royalty-free,
                        irrevocable, sub-licensable, perpetual licence to use, display, edit, modify, reproduce,
                        distribute, store, and prepare derivative works of Uploaded Content, to provide the Services and
                        to promote the Services, in any formats and through any channels, whether now or later existing
                        (and you represent and warrant that you have obtained the necessary permissions and rights to
                        grant us this licence). &nbsp;&nbsp;You acknowledge that WE HAVE NO LEGAL RELATIONSHIP WITH
                        CONTENT UPLOADERS, and we are not responsible for the Uploaded Content, or anything else related
                        to Content Uploaders. UPLOADED CONTENT AND CONTENT UPLOADERS ARE SOLELY YOUR RESPONSIBILITY. YOU
                        WILL BE RESPONSIBLE FOR ANY LOSSES OR DAMAGES WE SUFFER BECAUSE OF UPLOADED CONTENT OR CONTENT
                        UPLOADERS. Without limiting any other limitation of liability, indemnity or release set out in
                        this Agreement, you agree that we have no liability to you for any losses or damages you might
                        suffer because of Uploaded Content or Content Uploaders. &nbsp;&nbsp;Further, you agree to
                        indemnify and hold harmless Simplicity and its Others from any claim or demand (including reasonable
                        legal fees, expert fees and other reasonable litigation costs), arising from, incurred as a
                        result of, or in any manner related to, Uploaded Content or Content Uploaders. You represent and
                        warrant that Content Uploaders have the necessary rights (including, without limitation,
                        intellectual property rights) to: a) upload Uploaded Content; and b) enable you to use
                        (including offering for purchase) Uploaded Content, as part of your use of the Services. We do
                        not review Uploaded Content and you are solely responsible for ensuring it complies with this
                        Agreement and all applicable laws.</p>
                    <h2 id="disclaimer">Disclaimer</h2>
                    <p>The Services and the materials on our website are provided on an as-is, as-available, basis and
                        without warranties of any kind, expressed or implied. &nbsp;By accessing and using the Services
                        and the materials on our website, you acknowledge and agree that such access and use is entirely
                        at your own risk. &nbsp;We make no representation or warranties regarding the use or the results
                        of the Services or the materials on our website (whether provided directly by us or through
                        third parties or our affiliates), including, without limitation, that the Services or materials
                        on our website will be accurate, complete, correct, timely or suitable, that the Services and
                        the materials on our website &nbsp;are of merchantable quality or fit for a particular purpose,
                        that the Services and the materials on our website will be available or uninterrupted at all
                        times or or at any particular time, or that the Services and the materials on our website will
                        be free from errors, viruses or other harmful components. To the fullest extent permissible
                        pursuant to applicable law, we disclaim all warranties, express or implied, including, but not
                        limited to, warranties and conditions regarding use of the Services and the materials on our
                        website, including all implied warranties or conditions of merchantability, fitness for a
                        particular purpose or non-infringement, whether express or implied, or arising from a course of
                        dealing, usage or trade practice. &nbsp;&nbsp;We are not responsible for what others do with any
                        materials or information you choose to share using the Services.</p>
                    <h2 id="limitation-of-liability">Limitation of Liability</h2>
                    <p>THIS SECTION LIMITS OUR LIABILITY TO YOU. &nbsp;&nbsp;PLEASE READ IT CAREFULLY.</p>
                    <p>To the maximum extent permitted by law, under no circumstances shall you be entitled to recover
                        any special, incidental, consequential or indirect damages from Simplicity or its Others, which you
                        may suffer arising out of, caused by, or in connection with, the use, or inability to use, the
                        Services, any inaccuracy, incompleteness or incorrectness contained in the materials displayed,
                        accessed or used as part of the Services, or your reliance or acting upon the materials used as
                        part of the Services, including any loss or damages in the nature of or relating to lost
                        business, lost savings, lost data and/or profits, regardless of the cause and whether arising in
                        contract, tort, in equity, at law or otherwise, and whether or not Simplicity has or had been advised
                        of the possibility of such losses or damages. &nbsp;&nbsp;Unless otherwise agreed to by you and
                        Simplicity in writing, under no circumstances shall the liability of Simplicity and its Others to you, for
                        damages or losses suffered by you arising out of, related to or caused by, the Services, or the
                        use thereof, exceed a maximum equal to the amount actually paid by you for the Services in the
                        twelve (12) months’ period preceding such losses or damages. You acknowledge and agree that the
                        payments paid by you for the Services reflect the allocation of risk set forth in this Agreement
                        and that Simplicity would not enter into this Agreement without these limitations on liability.</p>
                    <p>You agree to release, remise and acquit Simplicity and its Others from any claims, actions, demands,
                        costs and expenses of any kind whatsoever, whether in contract, negligence or tort, at law or in
                        equity, or by statute or otherwise, howsoever caused, with respect to your use of, or our
                        operation of, the Services, except as may be set out in the preceding paragraph.</p>
                    <h2 id="indemnity">Indemnity</h2>
                    <p>You agree to indemnify and hold harmless Simplicity and its Others from any claim or demand (including
                        reasonable legal fees, expert fees and other reasonable litigation costs), arising from,
                        incurred as a result of, or in any manner related to, your use of the Services, including, but
                        not limited to: a) your breach of this Agreement; b) any misrepresentation made by you to any
                        third party; c) any third party claim in respect of the Services involving or related to your or
                        your Customers’ use of the Services; d) your ability or your Customers’ ability to access and
                        use the Services; or e) your violation of applicable laws, rules or regulations or the rights of
                        any third party.</p>
                    <h2 id="cancellation-termination--refund-policy">Cancellation, Termination &amp; Refund Policy</h2>
                    <p>If you breach any provision of this Agreement (as determined by us, in our sole discretion), we
                        may terminate this Agreement and you may no longer use the Services. &nbsp;&nbsp;We may, at any
                        time, and for any reason, with or without cause, and in our sole discretion, immediately suspend
                        or terminate (in whole or in part) your permission to use the Services, all without notice or
                        liability to you or to any other person.</p>
                    <p>We shall not be responsible for refunding or otherwise paying any funds, amounts or credits that
                        may be owed to you if we have suspended or terminated your permission to use the Services or any
                        part thereof. &nbsp;&nbsp;We reserve the right to cease, suspend or interrupt operation of, or
                        access to, the Services or any part thereof, and we shall not be required to provide any notice
                        in respect of such cessation, suspension or interruption of access, nor shall we have any
                        liability for such cessation, suspension or interruption of access.</p>
                    <p>##
                        While we do not offer any refunds on the Services and all sales are final, you may cancel your
                        subscription(s) at any time for no additional charges. Simply uninstall the app(s) (or other
                        Services) that you no longer want to use and your next invoice will automatically be adjusted to
                        exclude the app(s) (or other Services) you uninstall (provided that, in the case of subscription
                        based Services, your invoice will be adjusted during the first billing cycle after you uninstall
                        the app). If you no longer use any of our apps or Services and you no longer owe us any fees,
                        you won’t receive any further invoices. &nbsp;Please note that this cancellation policy may not
                        apply to custom developed Services/deliverables which you have engaged us to create/perform for
                        you.</p>
                    <p>When the Services are terminated by you or by us, for any reason, we will stop providing the
                        Services to you, and you will no longer be able to access your Simplicity account. &nbsp;&nbsp;You
                        shall uninstall and/or delete any apps or other software you have downloaded from us. The
                        termination of the Services may result in the loss of content which is associated with your
                        account and we are not responsible for storing or providing you copies of such
                        content. &nbsp;Personal data tied to your Simplicity account will be permanently deleted or anonymized
                        within 60 days of the termination date.</p>
                    <p>Provisions which, by their nature, should survive termination of this Agreement (including,
                        without limitation, obligations you have to pay or indemnify us, limitations on our liability
                        and any releases of us, terms regarding confidentiality, ownership or intellectual property
                        rights and rules which govern disputes between us), shall survive termination of this
                        Agreement.</p>
                    <h2 id="privacy-statement">Privacy Statement</h2>
                    <p>We are committed to protecting your privacy, in accordance with the terms and conditions of the
                        Simplicity <a href="/privacy">Privacy Statement</a> (the “Privacy Statement”). By accessing and
                        continuing to use the Services, including without limitation by registering or creating an
                        account or profile with Simplicity, and by providing personally identifiable information or personal
                        data to Simplicity through the Services, you are acknowledging that you have read our Privacy
                        Statement (which sets out how we process personal data, and our legal basis for processing
                        personal data) and that you agree and consent to us processing your personal data to provide the
                        Services to you in accordance with this Agreement and our Privacy Statement. Please ensure that
                        you have reviewed and understand our Privacy Statement before purchasing or subscribing for any
                        Services from us or providing personal data to us.</p>
                    <p>When you use the Services to transfer your Customers’ personal data to us, you represent and
                        warrant that you have your Customers’ consent to: a) the transfer of such personal data to us;
                        and b) our collection, use, retention, and disclosure of that and other personal data of your
                        Customers, for the purposes which are set out in the Privacy Statement. &nbsp;You further
                        acknowledge and agree that our use of your Customers’ personal data for these purposes is in our
                        capacity as your agent, only.</p>
                    <h2 id="confidentiality">Confidentiality</h2>
                    <p>We will not sell, share, or rent your personal data to or with any third party.</p>
                    <p>Your records are regarded as confidential and therefore will not be divulged to any unaffiliated
                        third party, other than our payment gateway in order to process your payment(s), and as is
                        otherwise necessary for the delivery of the Services and/or if legally required to do so, to the
                        appropriate authorities. &nbsp;Further information on the collection, use, retention, and
                        disclosure of personal data is available in our Privacy Statement.</p>
                    <h2 id="your-account">Your Account</h2>
                    <p>You may be required, when you use certain features of the Services, to create an account with us,
                        including a username and password. &nbsp;&nbsp;If we determine the username is in use by someone
                        else or it is otherwise offensive, we may refuse to allow you, in our sole discretion, to use
                        the chosen username. &nbsp;&nbsp;In addition, you are responsible for maintaining the
                        confidentiality of your password and you are responsible for all uses of your username, whether
                        or not you authorize such uses. You agree to notify us immediately of any actual or suspected
                        loss, theft or unauthorized use of your username and password, or your account. &nbsp;&nbsp;We
                        are not responsible for verifying your identity or the identity of anyone who uses your account,
                        and we are not liable for any loss or damage as a result of your failure to protect your
                        password, or as a result of unauthorized use of your username and/or password. You agree that
                        any registration information you provide will be true and accurate, and that you will update it,
                        as is necessary to keep it current. &nbsp;&nbsp;We reserve the right to automatically log you
                        out of your account after such a period of inactivity as we determine is reasonable, in the
                        circumstances.</p>
                    <p>We care about the security of our users. While we work hard to protect the security of your
                        Uploaded Content, personal data, and account, we cannot guarantee that unauthorized third
                        parties will not be able to defeat our security measures. Please notify us immediately in the
                        event of any actual or suspected breach or unauthorized access or use of your account.</p>
                    <h2 id="if-you-are-a-resident-of-the-european-union-you-have-the-right-to-delete-your-account-with-us-by-contacting-us-if-you-choose-to-permanently-delete-your-account-the-non-public-personal-data-that-we-have-associated-with-your-account-will-also-be-deleted">If
                        you are a resident of the European Union: You have the right to delete your account with us by
                        contacting us. If you choose to permanently delete your account, the non-public personal data
                        that we have associated with your account will also be deleted.</h2>
                    <h2 id="internet-connectivity-and-compatible-technology">Internet Connectivity and Compatible
                        Technology</h2>
                    <p>The availability and functioning of the Services depends on the availability of a properly
                        functioning Internet connection, as well as compatible hardware and software. &nbsp;&nbsp;We are
                        not responsible for ensuring uninterrupted access to the Internet or any charges you incur, in
                        association with your use of the Internet, nor are we responsible for ensuring your hardware and
                        software are compatible with the Services. &nbsp;&nbsp;You acknowledge that your access to and
                        use of the Services may be impaired or prevented by factors beyond our control (such as issues
                        with your computer system or Internet connectivity), and that we are not responsible for any
                        such factors, or their effects. &nbsp;&nbsp;We are not liable for any failure to backup or
                        restore any information or for interruptions, delay or suspension of access to or unavailability
                        of the Services, or any loss of such information, data or transmissions. It is your
                        responsibility to ensure that your data is backed up effectively.</p>
                    <h2 id="links-from-this-website">Links from this Website</h2>
                    <p>We do not monitor or review the content of other parties’ websites and services which are linked
                        to from this website, nor do we control the availability and content of such websites and
                        services. Opinions expressed or material appearing on such websites are not necessarily shared
                        or endorsed by us and we should not be regarded as the publisher of such opinions or material,
                        nor are we responsible for the accuracy of such opinions or material.</p>
                    <p>Please be aware that we are not responsible for the privacy practices, or content, of third party
                        websites and services. We encourage our users to be aware when they leave our website, and to
                        read the terms of use and privacy statements of the websites that they may link to or access.
                        You should evaluate the security and trustworthiness of any other website connected to our
                        website or accessed through our website yourself, before disclosing any personal data to it.
                        Simplicity will not accept any responsibility for any loss or damage in whatever manner, howsoever
                        caused, resulting from your disclosure to third parties of personal data.</p>
                    <h2 id="acceptable-use">Acceptable Use</h2>
                    <p>Without limiting anything else in this Agreement, you must not use (or cause or permit to be
                        used) this website or the Services:</p>
                    <ul>
                        <li>in any way that causes, or may cause, damage to our website or the Services, or impairment
                            of the availability or accessibility of our website or the Services;
                        </li>
                        <li>in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any
                            unlawful, illegal, fraudulent or harmful purpose or activity;
                        </li>
                        <li>to post, transmit, or otherwise make available any material that is or may be: (a)
                            threatening, harassing, degrading, hateful, or intimidating; (b) defamatory; (c) fraudulent
                            or tortious; (d) obscene, indecent, pornographic, or otherwise objectionable; or (e)
                            non-compliant with applicable privacy legislation or an infringement of another person’s
                            privacy, including without limitation by disclosing the personal data of another individual
                            without their knowledge and consent;
                        </li>
                        <li>to post, transmit, or otherwise make available, any material that may violate: a) our
                            proprietary rights; or b) the proprietary rights of any third party, including, without
                            limitation, copyrighted software, photographs, texts, videos or artwork or any moral rights
                            associated therewith;
                        </li>
                        <li>for any commercial purposes other than those which are expressly set out in this
                            Agreement;
                        </li>
                        <li>to copy, store, host, transmit, send, use, publish or distribute any material which consists
                            of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger,
                            rootkit or other malicious computer software;
                        </li>
                        <li>to conduct any systematic or automated data collection activities (including, without
                            limitation, scraping, data mining, data extraction and data harvesting) on or in relation to
                            this website without our prior express written consent;
                        </li>
                        <li>to impersonate any person or entity or misrepresent your affiliation with any other person
                            or entity;
                        </li>
                        <li>to engage in spamming, flooding, harvesting of email addresses or other personal
                            information, spidering, “ screen scraping”, “database scraping”, or any other activity with
                            the purpose of obtaining lists of users or other information (including any activity which
                            involves accessing or using the Services for purposes which are unrelated to the Services);
                        </li>
                        <li>to attempt to gain (or gain) unauthorized access to other computer systems through the
                            Services, or to obtain or attempt to obtain any materials or information through any means
                            not intentionally made available or provided for through the Services; &nbsp;&nbsp;or
                        </li>
                        <li>in a manner which is otherwise contrary to this Agreement.</li>
                    </ul>
                    <p>Any use of the Services which is contrary to the terms of this Agreement may result in the
                        immediate termination of this Agreement and your use of the Services, by us.</p>
                    <h2 id="international-terms">International Terms</h2>
                    <h2 id="if-you-are-not-a-canadian-resident-and-you-are-accessing-our-services-from-outside-of-canada-you-agree-to-transfer-certain-information-outside-your-home-country-to-us-and-that-you-will-follow-all-the-laws-that-apply-to-you">If
                        you are not a Canadian resident and you are accessing our Services from outside of Canada, you
                        agree to transfer certain information outside your home country to us, and that you will follow
                        all the laws that apply to you.</h2>
                    <h2 id="we-provide-our-services-outside-of-canada-however-our-servers-and-operations-are-located-primarily-in-canada-and-in-the-case-of-our-servers-canada-the-european-union-and-the-united-states-and-our-policies-and-procedures-are-based-primarily-on-canadian-law-because-of-this-the-following-provisions-apply-specifically-to-users-located-outside-of-canada-i-you-consent-to-the-transfer-storage-and-processing-of-your-information-including-but-not-limited-to-uploaded-content-and-any-personal-data-to-and-in-canada-andor-other-countries-and-ii-you-agree-to-comply-with-all-local-laws-rules-and-regulations-including-without-limitation-all-laws-rules-and-regulations-in-effect-in-the-country-in-which-you-reside-and-the-country-from-which-you-access-the-services-the-services-are-not-intended-for-distribution-to-or-use-by-any-person-or-entity-in-any-jurisdiction-or-country-where-such-distribution-or-use-would-be-contrary-to-law-or-regulation">We
                        provide our Services outside of Canada, however, our servers and operations are located
                        primarily in Canada (and, in the case of our servers, Canada, the European Union, and the United
                        States), and our policies and procedures are based primarily on Canadian law. Because of this,
                        the following provisions apply specifically to users located outside of Canada: (i) you consent
                        to the transfer, storage, and processing of your information, including but not limited to
                        Uploaded Content and any personal data, to and in Canada and/or other countries; and (ii) you
                        agree to comply with all local laws, rules, and regulations including, without limitation, all
                        laws, rules, and regulations in effect in the country in which you reside and the country from
                        which you access the Services. The Services are not intended for distribution to, or use by, any
                        person or entity in any jurisdiction or country where such distribution or use would be contrary
                        to law or regulation.</h2>
                    <h2 id="miscellaneous">Miscellaneous</h2>
                    <p>We are physically located within the Province of Manitoba, Canada. &nbsp;&nbsp;This Agreement
                        will be governed by the laws of the Province of Manitoba and the laws of Canada applicable
                        therein and shall be treated in all respects as a Manitoba contract, without reference to the
                        principles of conflicts of law. &nbsp;&nbsp;In the event of a dispute, you agree to submit to
                        the exclusive jurisdiction of Manitoba’ s courts.</p>
                    <p>Your use of the Services may also be subject to other local, state, provincial, national or
                        international laws and the use of the Services may be prohibited by law in some jurisdictions.
                        By using the Services you certify that the laws of the jurisdiction in which you are using the
                        Services, permit the use of it, and you are responsible for complying with all local laws in
                        your jurisdiction. &nbsp;&nbsp;If the laws which apply to your use of the Services would
                        prohibit the enforceability of this Agreement, or impose any additional burdens on us, or confer
                        to you rights which are materially different than those granted to you under this Agreement, you
                        are not authorized to use the Services and you agree to remove them from any computer or other
                        device on which they may be installed.</p>
                    <p>We expressly exclude the United Nations Convention on Contracts for the International Sale of
                        Goods and The International Sale of Goods Act &nbsp;(Manitoba), C.C.S.M. c. S11, as amended,
                        replaced or re-enacted from time to time.</p>
                    <p>You agree to waive any right you may have to: a) a trial by jury; and b) commence or participate
                        in any class action action against us related to your use of the Services, the exchange of
                        electronic documents between us or this Agreement and, where applicable, you also agree to opt
                        out of any class action proceedings against us.</p>
                    <p>The Services offered by Simplicity are directed towards and designed for the use of persons above the
                        age of majority in their respective province, state, or country. &nbsp;Persons under the age of
                        majority are not permitted to use the Services on their own, and Simplicity will not approve
                        applications of, or establish, or maintain accounts or memberships for any persons below their
                        respective region’s age of majority.</p>
                    <p>If you are younger than 18, you may use the Services under the supervision of a parent or legal
                        guardian. &nbsp;&nbsp;Otherwise, you must be 18 or older to use the Services and in no
                        circumstances shall people under the age of majority in your state, province, or country, use
                        the Services. Use of the Services by anyone under 13 years of age is strictly prohibited.</p>
                    <p>The parties hereto have required that this Agreement and all documents relating thereto be drawn
                        up in English. Nous avons demandé que cette convention ainsi que tous les documents qui s’ y
                        rattachent soient rédigés en anglais.</p>
                    <p>Words importing the singular include the plural and vice versa; and words importing gender
                        include all genders, including the neuter gender, as the context may require.</p>
                    <p>We will not be liable for the failure or delay in our performance of our obligations under this
                        Agreement due to any cause beyond our reasonable control, including, but not limited to: a) acts
                        of God; or b) failure or disruptions in third-party-controlled or operated communications
                        facilities; or c) worms, viruses and other disabling or disruptive software, communications or
                        files. &nbsp;</p>
                    <p>Our failure to exercise or enforce any right or provision of this Agreement shall not constitute
                        a waiver of such right or provision. &nbsp;&nbsp;If any provision of these terms shall be
                        unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable
                        from these terms and shall not affect the validity and enforceability of any remaining
                        provisions. &nbsp;&nbsp;We may assign this Agreement without restriction. You may not assign
                        your rights under this Agreement without our prior written permission and any attempt by you to
                        do so shall be void. This Agreement is binding on you and us, and your and our respective
                        successors (including any successor by reason of amalgamation of any party), heirs, legal
                        representatives and permitted assigns, as the case may be.</p>
                    <h2 id="communication">Communication</h2>
                    <p>When you visit our website, use the Services or send email to us, you are communicating with us
                        electronically. &nbsp;&nbsp;We may communicate with you by email or by posting notices on our
                        website. You agree that all agreements, notices, disclosures and other communications that we
                        provide to you electronically, satisfy any legal requirement that such communications be in
                        writing and/or signed.</p>
                    <p>You are not required to agree to receive promotional messages from us as a condition of using the
                        Services. However, by electing to submit your contact information to us and agreeing to this
                        Agreement, you agree to receive certain communications from Simplicity. These communications may
                        include, for example, operational communications concerning your account or use of the Services,
                        updates concerning new and existing features of the Services or Simplicity websites, and promotional
                        communications concerning promotions run by us or third parties, and news relating to the
                        Services and industry developments. If you wish to stop receiving promotional communications
                        from us, follow the instructions we provide in the communication for that category of
                        communication.</p>
                    <p>All notices given by you to us, must be given to us at the address set out below or by email to
                        support@simplicitycommerce.com. &nbsp;We may give notice to you at the email or mailing address you
                        provide to us when you place an order with us, or register to use the Services, or by way of a
                        general posting on our website. &nbsp;&nbsp;Notice will be deemed to be received immediately
                        when posted on our website, twenty-four (24) hours after an email is sent and three (3) days
                        after a letter is posted. In proving the service of any notice, it will be sufficient to prove,
                        in the case of a letter, that such letter was properly addressed, stamped and placed in the
                        post. &nbsp;&nbsp;In the case of an email, notice will be proved by showing the email was sent
                        to the specified email address of the recipient of the notice.</p>
                    <p>Our contact information can be found on our <a href="/contact">Contact Us</a>&nbsp;link on our
                        website.</p>
                </div>
            </TextWrapper>
          </MainContentSection>
        </Container>
      </MainWrapper>
  );
}

export default ErrorPage;
